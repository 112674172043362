
 
<mat-dialog-header>
    <h1> Asignar Coordenada </h1>
</mat-dialog-header>

<mat-dialog-content>

    <div fxFill fxLayout="row">

        <mat-form-field >
            <mat-label> {{ 'product' | localize }} </mat-label>
            <input matInput type="text" [(ngModel)]="selectedProduct && selectedProduct.name" [ngModelOptions]="{standalone: true}" [disabled]="true" >
        </mat-form-field>

    </div>


    <div fxFill fxLayout="row">


        <mat-form-field [disabled]="isReadonly()">

            <mat-label>{{'location'|localize}}</mat-label>

            <mat-select [(ngModel)]="selectedLocation" name="selectedLocation" (selectionChange)="locationChange($event)">
            <mat-option [value]=""> </mat-option>
            <mat-option *ngFor="let location of locations$ | async" [value]="location">{{ location.code }}</mat-option>
            </mat-select>

        </mat-form-field>

    </div>

    <loading-indicator *ngIf="isLoading"></loading-indicator>

</mat-dialog-content>

<mat-dialog-actions>

    <button mat-button class="btn__secondary btn-width" [mat-dialog-close]="false"> Cancelar
    </button>

    <button *ngIf="action != 0" mat-button type="submit" class="btn__primary btn-width" (click)="send()" [disabled]="!enableSendBtn()" >
        <span> {{ getActionLabel() }} </span>
    </button>

</mat-dialog-actions>
