import { Component, OnInit } from '@angular/core';
import {Location, Appearance} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { TrackingService } from '../services/tracking.service';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DeliveryDataDto } from '../oms/services/service-proxy/service-proxies';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { SuccessValidationMessageComponent } from './success-validation-message/success-validation-message.component';

@Component({
  selector: 'app-data-validation-landing',
  templateUrl: './data-validation-landing.component.html',
  styleUrls: ['./data-validation-landing.component.scss']
})
export class DataValidationLandingComponent implements OnInit {

  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;

  public editable = false;
  public loading = true;
  public error = false;
  public status : string;

  public urlTracking ;

  direction = {
    country: "",
    province: "",
    city: "",
    street: "",
    streetNumber: "",
    postalCode: "",
    depto: "",
    tel: "",
    countryPhoneCode: "",
    latitude: 0,
    longitude: 0
  };

  secureId = "";

  countryCode = "";

  constructor(private trackingService: TrackingService, private route: ActivatedRoute, private dialog: MatDialog) { }

  ngOnInit() {
    this.zoom = 10;

    this.route.paramMap.subscribe(x => {

      var locationhref = window.location.href;
      this.urlTracking = locationhref.replace("validate","tracking");

      this.secureId = x.get("token");
      this.trackingService.validateOrderBySecureId(x.get("token"))
      .pipe(finalize(() => this.loading = false))
      .subscribe(x => {
        //this.editable = true;
        if (x.error) {
          //alert(x.message);
          this.error = true;
        } else {
          this.editable = x.editable;
          this.status = x.status;
          this.countryCode = x.countryCode;
          this.direction.country = x.deliveryData.country,
          this.direction.city = x.deliveryData.city,
          this.direction.province = x.deliveryData.state,
          this.direction.street = x.deliveryData.street,
          this.direction.streetNumber = x.deliveryData.number,
          this.direction.tel = x.deliveryData.phoneNumber,
          this.direction.latitude = x.deliveryData.geoLat,
          this.direction.longitude = x.deliveryData.geoLong;
          this.direction.postalCode = x.deliveryData.postalCode;
          this.direction.depto = x.deliveryData.complement;
        }
      })
    })
  }

  onAutocompleteSelected(event: PlaceResult) {
    this.direction.country = '';
    this.direction.province = '';
    this.direction.city = '';
    this.direction.street = '';
    this.direction.streetNumber = '';
    this.direction.postalCode = '';
    if (event.address_components.filter(x => x.types.includes('country')).length)
      this.direction.country = event.address_components.filter(x => x.types.includes('country'))[0].long_name;
    if (event.address_components.filter(x => x.types.includes('administrative_area_level_1')).length)
      this.direction.province = event.address_components.filter(x => x.types.includes('administrative_area_level_1'))[0].long_name;
    if (event.address_components.filter(x => x.types.includes('locality')).length)
      this.direction.city = event.address_components.filter(x => x.types.includes('locality'))[0].long_name;
    if (event.address_components.filter(x => x.types.includes('route')).length)
      this.direction.street = event.address_components.filter(x => x.types.includes('route'))[0].long_name;
    if (event.address_components.filter(x => x.types.includes('street_number')).length)
      this.direction.streetNumber = event.address_components.filter(x => x.types.includes('street_number'))[0].long_name;
    if (event.address_components.filter(x => x.types.includes('postal_code')).length)
      this.direction.postalCode = event.address_components.filter(x => x.types.includes('postal_code'))[0].long_name;
    else {
      var cp = '';

      var index = 0;
      while (cp.length == 0) {
        cp =  prompt(
          index == 0? 
            'No se ha podido determinar el Código Postal de la dirección. Favor de completarlo manualmente':
            'No ha ingresado un Código postal. Favor de ingresarlo'
        );

        index++;
      }

      this.direction.postalCode = cp;
    }
  }
  onLocationSelected(location: Location) {
    this.direction.latitude = location.latitude;
    this.direction.longitude = location.longitude;
  }

  updateDeliveryData() {
    const deliveryData = {
      secureId: this.secureId,
      country: this.direction.country,
      city: this.direction.city,
      geoLat: this.direction.latitude,
      geoLong: this.direction.longitude,
      street: this.direction.street,
      number: this.direction.streetNumber,
      state: this.direction.province,
      postalCode: this.direction.postalCode,
      phoneNumber: this.direction.tel,
      depto: this.direction.depto
    };
    this.loading = true;
    this.trackingService.updateDeliveryData(deliveryData)
    .pipe(finalize(() => this.loading = false))
    .subscribe(x => {
      if (x.error) {
        alert(x.message);
      } else {
        this.openModal(SuccessValidationMessageComponent, {});
      }
    })
  }
  navigateToCountry() {
    if (this.countryCode == 'AR') {
      window.location.href = 'https://www.puppis.com.ar';
    } else {
      window.location.href = 'https://puppis.com.co';
    }
  }

  protected openModal(component, data): MatDialogRef<any, any> {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = data;
    dialogConfig.panelClass = 'data-valdiation-landing-corner-class';

    return this.dialog.open(component, dialogConfig);
  }
}
